<template>
	<v-dialog persistent v-model="dialog" width="800">
		<v-card :loading="loading" class="pb-3">
			<v-card-title>上传{{allowPdf ? '文件' : '图片'}}</v-card-title>
			<v-card-text class="pb-0">
				<file-pond
					ref="pond"
					label-idle="选取文件或拖入此处..."
					:allow-multiple="true"
					:accepted-file-types="afts"
					labelFileTypeNotAllowed="文件类型无效！"
					:files="myfiles"
					v-on:init="handleFilePondInit"
					@updatefiles="updatefiles"
					/>
			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn large color="primary" :loading="loading" :disabled="loading" @click.stop="save">上传</v-btn>
				<v-btn large outlined :disabled="loading" @click.stop="close">返回</v-btn>
			</v-card-actions>
		</v-card>
    </v-dialog>
</template>

<script>
    import {md5p} from '../utils'

	import vueFilePond from "vue-filepond"
	import "filepond/dist/filepond.min.css"
	import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
	import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css"
	import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
	import FilePondPluginImagePreview from "filepond-plugin-image-preview"
	import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview"

	const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginPdfPreview);

	export default {
		props: {
			value: Boolean,
			clouddir: String,
			files: Array,
			allowPdf: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				loading: false,
				myfiles: [],
			}
		},
		computed: {
			dialog: {
				get() {
					return this.value;
				},
				set(v) {
					if (!v) {
						this.$emit('input', false);
					}
				}
			},
			afts() {
				return this.allowPdf ? ['image/jpeg','application/pdf'] : 'image/jpeg'
			},
		},
		watch: {
			value(v) {
				if (v) {
					this.myfiles = this.files ? this.files : [];
				}
			},
		},
		methods: {
			close() {
				this.dialog = false;
				this.$emit('close');
			},
			handleFilePondInit() {
			},
			updatefiles(f) {
			},
			async save() {
				const f = this.$refs.pond.getFiles();
				if (f.length === 0) {
					this.$dialog.message.error('请先添加图片文件再上传');
					return;
				}
				const afts = this.allowPdf ? ['jpg','jpeg','pdf'] : ['jpg','jpeg'];
				const validtype = f.every(x => afts.includes(x.fileExtension.toLowerCase()));
				if (!validtype) {
					this.$dialog.message.error('不能上传无效类型文件');
					return;
				}
				this.loading = true;
				try {
					const r = [];
					for(const i in f) {
						const v = f[i];
						const md5 = await md5p(v.file);
						const filename = this.clouddir + '/' + md5.substr(0, 8) + '.' + v.fileExtension.toLowerCase();
						const rf = await this.$tcbapp.uploadFile({
//							config: {env: 'huasan-6gaadaha7ceb62a9'},
							cloudPath: filename,
							filePath: v.file
						});
						r.push(rf.download_url);
					}
					const files = [...new Set(r)];	//消除重复
					this.$emit('saved', files);
					this.close();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('上传图片失败');
				}
				this.loading = false;
			}
		}
	}
</script>

<style>
	.filepond--item {
		width: calc(33% - 0.5em)!important;
	}
</style>
