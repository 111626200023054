<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="45" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems" dense
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap pb-1" ref="tabletopbar">
						<v-text-field placeholder="姓名/证件号/手机号" v-model="psearch" clearable hide-details
							append-icon="search" @click:append="loadData" @keyup.enter.native="loadData" style="flex: 0 1 auto" class="ml-2"/>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.progress.operative="{ item }">
					{{formatTime(item.progress.operative)}}
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				</v-data-table>
			</pane>
			<pane min-size="30" size="55" v-if="(selected.length > 0)">
				<div style="max-height:100%;background-color:white;" class="overflow-y-auto pl-2">
					<v-tabs v-model="tab">
						<v-tab>术前检查报告</v-tab>
						<v-tab-item class="pt-3">
							<v-btn outlined rounded color="primary" width="200" @click.stop="showUpload()" class="ml-4">上传术前检查报告</v-btn>
						</v-tab-item>
						<v-tab>内镜报告</v-tab>
						<v-tab-item class="pt-3">
							<v-btn outlined rounded color="primary" width="200" @click.stop="showUpload({type:'胃镜'})" class="ml-4">上传胃镜检查报告</v-btn>
							<v-btn outlined rounded color="primary" width="200" @click.stop="showUpload({type:'肠镜'})" class="ml-4">上传肠镜检查报告</v-btn>
						</v-tab-item>
						<v-tab>病理报告</v-tab>
						<v-tab-item class="pt-3">
							<v-btn outlined rounded color="primary" width="200" @click.stop="showUpload({type:'胃镜'})" class="ml-4">上传胃镜病理报告</v-btn>
							<v-btn outlined rounded color="primary" width="200" @click.stop="showUpload({type:'肠镜'})" class="ml-4">上传肠镜病理报告</v-btn>
						</v-tab-item>
					</v-tabs>
					<v-container fluid>
						<v-divider/>
						<v-row class="mt-1">
							<v-card v-for="(r,idx) in rpts" :key="idx" class="ma-2" max-width="200">
								<v-btn v-if="r.canDelete && canEdit" fab x-small absolute right @click.stop="remove(r)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
								<v-img v-if="r.isPdf" @click.stop="showPdf(idx)" height="283">
									<object :data="r.url+'#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" width="100%" height="100%" style="pointer-events:none;"/>
								</v-img>
								<v-img v-else :src="r.url" lazy-src="../assets/report.png" width="200" height="283" @click.stop="showImage(idx)">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-card>
						</v-row>
					</v-container>
	            </div>
			</pane>
		</splitpanes>
		<image-uploader v-model="iu" :clouddir="clouddir" @saved="onPicSaved" allowPdf/>
		<viewer v-if="rpts.length > 0" @inited="inited" ref="viewer" :images="rpts.filter(x=>!x.isPdf)" style="display:none">
			<template slot-scope="scope">
				<img v-for="src in scope.images" :src="src.url" :key="src.url">
			</template>
		</viewer>
		<v-dialog v-model="dlgPdf" width="60%">
			<v-card style="height:90vh">
				<object v-if="!!curpdf" :data="curpdf+'#navpanes=0'" type="application/pdf" width="100%" style="height:calc(100% - 6px)"></object>
			</v-card>
		</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import ImageUploader from '@/components/ImageUploader3.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {sortBy:['progress.operative'], sortDesc:[true]},
				totalItems: 0,
				tableheight: undefined,
				canEdit: true,
				psearch: '',
				rpts0: [[],[],[]],
				iu: false,
				dlgPdf: false,
				curpdf: '',
				tab: 0,
				field: '',
				fields: ['examReports', 'endoscopyReports', 'pathologyReports'],
				meta: {},
            }
        },
        created() {
			this.formatTime = formatTime;
        },
        mounted() {
			this.authed = this.$hasPrivilege(['报告中心','增删报告']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege(['增删报告']);
			this.fetchData();
        },
		computed: {
			clouddir() {
				if (this.selected.length === 0) return "";
				return `wp3/PathologyReports/${this.selected[0]._id}`;
			},
			rpts() {
				return this.rpts0[this.tab];
			},
		},
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 39;
				setTimeout(() => {
    				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				filter.push({hide:_.neq(true)});
				const f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			showPdf(idx) {
				this.curpdf = this.rpts[idx].url;
				this.dlgPdf = true;
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				if (!item.opts) return '';
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			showUpload(meta) {
				this.meta = meta || {};
				this.iu = true;
			},
			async onPicSaved(files, field, meta) {
				if (this.selected.length === 0) return;
				const id = this.selected[0]._id;
				this.meta.source = 'nexus';
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'uploadFiles',
						data: {
							id,
							files,
							field: this.fields[this.tab],
							meta: this.meta,
						}
					});
					await this.fetchData();
					this.selected[0] = this.items.find(x => x._id === id);
					this.fetchRpts();
				} catch(err) {
					this.$dialog.message.error('上传文件失败');
					console.error(err);
				}
				this.loading = false;
			},
			fetchRpts() {
				const s = this.selected[0];
				for (let i in this.fields) {
					const f = this.fields[i];
					if (s[f]) {
						s[f].forEach(x => {
							x.canDelete = true;
							x.isPdf = x.url.substring(x.url.length - 3).toLowerCase() === 'pdf';
							x.url += "?t=" + Math.random();	//对抗缓存
						});
						this.$set(this.rpts0, i, s[f]);
					} else {
						this.$set(this.rpts0, i, []);
					}
				}
			},
			async remove(elem) {
				const res = await this.$dialog.warning({
					text: '确定要删除此报告？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				let {url, meta} = elem;
				this.loading = true;
				try {
					const res = await this.$callCloudFunc({
						funcname:'removeFile',
						data: {
							id,
							field: this.fields[this.tab],
							elem: {url},
						}
					});
					await this.fetchData();
					this.selected[0] = this.items.find(x => x._id === id);
					this.fetchRpts();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.fetchRpts();
			}
		},
        components: { Splitpanes, Pane, ImageUploader }
    }
</script>
